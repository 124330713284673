.sumber-sumber {
  display: flex;
  flex-direction: row;
  flex-wrap: wrap;
  justify-content: center;
}

.sumber {
  min-width: 40vw;
  max-width: 40vw;
  margin: 20px;
}

.imej-sumber {
  width: 15%;
  border: solid 1px black;
  margin-top: 2rem;
}

.tajuk-penulis {
  margin-top: 2rem;
}

.lsgr-container {
  width: 100%;
  margin: 2rem auto;
  display: flex;
  justify-content: center;
}

.disiderata-sumber {
  text-align: left;
  width: 80vw;
  margin: auto;
}

/* iPhone 12/13 Pro Max iOS 14.6 */
@media (max-width: 428px) {
  .sumber {
    min-width: 80vw;
    max-width: 80vw;
  }

  .imej-sumber {
    width: 50%;
  }

  .lsgr-container {
    width: 80%;
  }

  .disiderata-sumber {
    width: 100vw;
  }
}