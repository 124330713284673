.cari-kontena {
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
}

.tiada-pantun-kontena {
  height: 100%;
  display: flex;
  flex-direction: column;
  align-items: center;
}

.cari-form {
  margin: 0 2rem;
}

.tiada-pantun-teks {
  color: var(--bs-border-color-translucent);
}

.teks-hasil-carian {
  font-size: smaller;
}

#mobileCariPantunHelp {
  display: none;
}

/* iPhone 12/13 Pro Max iOS 14.6 */
@media (max-width: 560px) {
  #cari-pantun {
    display: none;
  }

  #cariPantunHelp {
    display: none;
  }

  #mobileCariPantunHelp {
    display: initial;
  }
}
