.tema-tema {
  display: flex;
  flex-direction: row;
  flex-wrap: wrap;
  justify-content: center;
  align-items: center;
}

.kad {
  width: 20vw;
  height: 15vh;
  margin: 20px;
  /* border: 1px solid rgba(0, 0, 0, 0.175); */
  /* border-radius: 10px; */
  display: flex;
  justify-content: center;
  align-items: center;
}

a {
  text-decoration: none;
  cursor: pointer;
}

@media (max-width: 320px) {
  .kad {
    width: 80vw;
  }
}

/* Galaxy Note 20 Ultra Android 11 */
@media (max-width: 428px) {
  .kad {
    width: 80vw;
  }
}
