big {
  font-size: 100px;
}

#sasaran:hover b {
  display: none;
}

#sasaran:hover::before {
  content: "1,000,000";
  font-weight: bold;
}
