.App {
  text-align: center;
  display: flex;
  flex-direction: column;
  height: 100vh;
}

/* TODO: potentially pushing HTML elements out of viewport, had to get rid of the class for Teroka and Sumber */
.App-main {
  height: 100%;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
}

.App-link {
  color: #61dafb;
}

.under-construction-super-container {
  text-align: center;
  /* color: var(--bs-warning-text-emphasis); */
  color: var(--bs-danger-text-emphasis);
  /* color: black; */
  border-left: solid 10px var(--bs-primary);
  /* border-radius: 30px 0 20px 0; */
  border-radius: 0 0 20px 0;
  width: 50vw;
  justify-content: center;
  margin: auto;
}

.under-construction-container {
  text-align: center;
  /* color: var(--bs-warning-text-emphasis); */
  color: var(--bs-danger-text-emphasis);
  /* color: black; */
  border-left: solid 10px var(--bs-danger);
  /* border-left: solid 10px crimson; */
  /* border-radius: 30px 0 20px 0; */
  border-radius: 0 0 20px 0;
  width: inherit;
  justify-content: center;
  margin: auto;
}

.under-construction {
  text-align: center;
  /* color: var(--bs-warning-text-emphasis); */
  color: var(--bs-danger-text-emphasis);
  /* color: black; */
  /* border-left: solid 10px var(--bs-success); */
  border-left: solid 10px var(--bs-danger);
  /* border-left: solid 10px limegreen; */
  /* border-radius: 20px 0 20px 0; */
  border-radius: 0 0 20px 0;
  /* width: inherit; */
  width: 50vw;
  margin: auto;
  margin-bottom: 1rem;
  padding: 1rem;
  /* background-color: #e6e6e6; */
  background-color: var(--bs-warning);
}

kbd {
  background-color: #eee;
  border-radius: 3px;
  border: 1px solid #b4b4b4;
  box-shadow:
    0 1px 1px rgba(0, 0, 0, 0.2),
    0 2px 0 0 rgba(255, 255, 255, 0.7) inset;
  color: #333;
  display: inline-block;
  font-size: 0.85em;
  font-weight: 700;
  line-height: 1;
  padding: 2px 4px;
  white-space: nowrap;
}